
import * as cloudflareRuntime$GbCRNKJaOp from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'

export const imageOptions = {
  "screens": {
    "24": 24,
    "40": 40,
    "48": 48,
    "80": 80,
    "100": 100,
    "128": 128,
    "160": 160,
    "192": 192,
    "200": 200,
    "214": 214,
    "256": 256,
    "370": 370,
    "428": 428,
    "516": 516,
    "520": 520,
    "600": 600,
    "720": 720,
    "740": 740,
    "1032": 1032,
    "1040": 1040,
    "1440": 1440,
    "2048": 2048,
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "avatarSm": 20,
    "avatar": 32,
    "avatarBigger": 64,
    "themebubble": 96,
    "2xs": 300
  },
  "presets": {
    "avatar": {
      "modifiers": {
        "width": 32,
        "height": 32,
        "quality": 80,
        "fit": "cover"
      }
    },
    "candidateSmall": {
      "modifiers": {
        "width": 60,
        "height": 60,
        "quality": 80,
        "fit": "cover"
      }
    }
  },
  "provider": "cloudflare",
  "domains": [
    "www.altinget.se",
    "legacy.altinget.se",
    "www.altinget.no",
    "legacy.altinget.no",
    "www.altinget.dk",
    "legacy.altinget.dk",
    "www.mm.dk",
    "legacy.mm.dk"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$GbCRNKJaOp, defaults: {"baseURL":"https://www.mm.dk","modifiers":{"quality":80,"fit":"crop"}} }
}
        